import React from 'react';
import styled from 'styled-components';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaPaypal, FaShieldAlt } from 'react-icons/fa'; // Importing PayPal icon

const PriceCalculatorCard = ({ formData }) => {
  return (
    <Card>
      <Title>Order Summary</Title>
      <Text>
        <strong>
          <p>Academic Level: {formData.academicLevel}</p>
          <p>Type of Paper: {formData.typeOfPaper}</p>
          <p>Discipline: {formData.discipline}</p>
          <p>Topic: {formData.topic}</p>
          <p>Instructions: {formData.instructions}</p>
          <h5>Total Price: ${formData.totalPrice}</h5>
        </strong>
      </Text>

      <CouponSection>
        <CouponInput type="text" placeholder="Coupon Code" />
        <ApplyButton>Apply</ApplyButton>
      </CouponSection>

      <SafeCheckoutButton>Safe checkout</SafeCheckoutButton>

      <PaymentOptions>
        <PaymentLogo><FaCcVisa size={32} color="#1a1f71" /></PaymentLogo>
        <PaymentLogo><FaCcMastercard size={32} color="#ff5f00" /></PaymentLogo>
        <PaymentLogo><FaCcAmex size={32} color="#2e77bc" /></PaymentLogo>
        <PaymentLogo><FaPaypal size={32} color="#003087" /></PaymentLogo>
      </PaymentOptions>

      <SecureBadge>
        <FaShieldAlt size={40} color="green" />
        <SecureText>Secured by McAfee</SecureText>
      </SecureBadge>
    </Card>
  );
};

export default PriceCalculatorCard;

// Styled Components
const Card = styled.div`
  background-color: #e8f3ff;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
`;

const Title = styled.h6`
  font-size: 1.1em;
  margin-bottom: 15px;
`;

const Text = styled.p`
  margin: 5px 0;
  font-size: 0.9em;
  color: #333;
`;

const CouponSection = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

const CouponInput = styled.input`
  padding: 8px;
  width: 70%;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const ApplyButton = styled.button`
  padding: 8px 12px;
  background-color: #4381c1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const SafeCheckoutButton = styled.button`
  margin-top: 15px;
  background-color: #003366;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
`;

const PaymentOptions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const PaymentLogo = styled.div`
  display: inline-block;
`;

const SecureBadge = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const SecureText = styled.p`
  font-size: 0.85em;
  color: #333;
`;
