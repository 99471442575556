// frontend/src/components/Signup.js

import React, { useState, useContext } from 'react'; 
import axios from 'axios';
import {
  Container,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Button
} from 'reactstrap';
import styled from 'styled-components';
import { AuthContext } from '../context/AutoContex'; // Ensure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import '@fontsource/poppins'; // Regular
import '@fontsource/montserrat'; // Bold

// Styled components for a better look
const StyledContainer = styled(Container)`
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
`;

const Header = styled.div`
  width: 40%;
  height: 10vh;
  display: flex;
  justify-content: center;
  gap: 30px; /* Align content to the right */
  align-items: center; /* Vertically center the items */
  background: linear-gradient(135deg, #025B50, #023A5B);
  color: white;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px; /* Add horizontal padding */
  border-radius: 15px;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 600px) {
    width: 90%;
    height: auto;
    flex-direction: column; /* Stack vertically on small screens */
    justify-content: center;
  }
`;

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 2.0rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 700; /* Increased font weight */
  margin-right: 20px;

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 10px; /* Add spacing when stacked */
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #ffffff;

  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  background-color: white;
  height: auto;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const StyledForm = styled(Form)`
  padding: 20px; /* Increased padding for better spacing */
  width: 100%;
  font-family: 'Poppins', sans-serif;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  font-size: 1rem; /* Increased font size for better readability */
  padding: 10px; /* Increased padding */
  font-family: 'Poppins', sans-serif;

  &:focus {
    border-color: #02505B;
    box-shadow: 0 0 5px rgba(2, 91, 59, 0.5);
    outline: none;
  }

  &::placeholder {
    color: #b0b0b0;
    opacity: 1;
  }
`;

const StyledButton = styled(Button)`
  background-color: #02505B;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1rem;
  padding: 12px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #02245B;
    transform: scale(1.02);
  }

  &:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
    transform: none;
  }

  &:focus {
    outline: 2px solid #025B50;
    outline-offset: 2px;
  }
`;

const ErrorMessage = styled.div`
  color: #FF6B6B;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: #28a745;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 8px;
`;

const CheckboxLabel = styled.label`
  margin: 0;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
`;

const Signup = () => {
  const { setUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    name: '', 
    phone: '', 
    password: '',
    confirmPassword: '',
    terms: false
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple client-side validation
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    
    if (!formData.terms) {
      setError('You must agree to the terms and conditions.');
      return;
    }

    setError('');
    setSuccess('');
    setLoading(true); // Start loading

    try {
      const res = await axios.post('http://localhost:5000/signup', {
        username: formData.username,
        email: formData.email,
        name: formData.name, 
        phone_number: formData.phone, 
        password: formData.password,
      });

      if (res && res.data) {
        setSuccess(res.data.message);
        setUser(res.data.user);
        // Reset form data
        setFormData({
          username: '',
          email: '',
          name: '', 
          phone: '', 
          password: '',
          confirmPassword: '',
          terms: false
        });
      } else {
        setError('Something went wrong, please try again.');
      }
    } catch (err) {
      console.error('Signup error:', err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <StyledContainer>
      <Header>
      <IconWrapper>
            <FontAwesomeIcon icon={faUserPlus} />
          </IconWrapper>
        <TitleIconContainer>
        
          <Title>Sign Up</Title>
         
        </TitleIconContainer>
      </Header>
      <StyledCard>
        <CardBody>
          <StyledForm onSubmit={handleSubmit}>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && <SuccessMessage>{success}</SuccessMessage>}

            {/* Username */}
            <FormGroup>
              <StyledInput
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Username"
                required
              />
            </FormGroup>

            {/* Email */}
            <FormGroup>
              <StyledInput
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
            </FormGroup>

            {/* Full Name */}
            <FormGroup>
              <StyledInput
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name"
                required
              />
            </FormGroup>

            {/* Phone Number */}
            <FormGroup>
              <StyledInput
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                required
              />
            </FormGroup>

            {/* Password */}
            <FormGroup>
              <StyledInput
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </FormGroup>

            {/* Confirm Password */}
            <FormGroup>
              <StyledInput
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm Password"
                required
              />
            </FormGroup>

            {/* Terms and Conditions */}
            <FormGroup>
              <CheckboxContainer>
                <Checkbox
                  id="terms"
                  name="terms"
                  checked={formData.terms}
                  onChange={handleChange}
                />
                <CheckboxLabel htmlFor="terms">
                  I agree to the <a href="/terms">terms and conditions</a>
                </CheckboxLabel>
              </CheckboxContainer>
            </FormGroup>

            <StyledButton type="submit" disabled={loading}>
              {loading ? 'Signing Up...' : 'Sign Up'}
            </StyledButton>
          </StyledForm>
        </CardBody>
      </StyledCard>
    </StyledContainer>
  );
};

export default Signup;
