import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PageHeader from '../Elements/Header';
import HeaderImage from '../../assets/img/images/img8.jpg';
import { 
  MdSchool, 
  MdBook, 
  MdEdit, 
  MdAssignment, 
  MdBarChart, 
  MdFileCopy 
} from 'react-icons/md';

// Styled Components
const Section = styled.section`
  padding: 60px 20px;
  background-color: #F2F8F8;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 50px;

  h2 {
    color: #023A5B;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    color: #333;
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
      padding: 0 10px;
    }
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
`;

const ServiceCard = styled.div`
  background-color: #E6F2F2;
  border-radius: 12px;
  padding: 30px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  }

  &:hover a {
    background-color: #025B50;
  }
`;

const IconWrapper = styled.div`
  color: #02505B;
  margin-bottom: 20px;
  
  svg {
    width: 60px;
    height: 60px;
  }
`;

const ServiceTitle = styled.h5`
  color: #023A5B;
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

const ServiceDescription = styled.p`
  color: #333;
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom:40px;
`;

const OrderButton = styled(Link)`
 background-color: #025B3A;
  color: white;
  margin-top:10px;
  padding: 12px 30px;
  border-solid:#025B3A;;
  border-radius: 25px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 15px;
  position: absolute;
  left: 35%;
  transform: translate(-10%, 15px);
  bottom: 20px;

  &:hover {
    background-color: #025B50;
    color:#fff;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    color:#fff;
    box-shadow: 0 0 0 3px rgba(2, 91, 58, 0.3);
  }
`;

// Service Data
const services = [
  {
    icon: <MdSchool />,
    title: 'Assignments',
    description: 'Need top-quality academic writing? Our team offers reliable and affordable assignment help to students in high school, college, and grad school. Ace your essays and exams with our expert support!',
    delay: '0.1s',
  },
  {
    icon: <MdBook />,
    title: 'Thesis',
    description: 'Writing a standout university thesis can be daunting, especially with the added challenge of defending it. Our professional thesis writing service handles the hard part for you. We provide well-researched, custom papers designed to impress even the toughest professors. Let us help you conquer your thesis with ease!',
    delay: '0.3s',
  },
  {
    icon: <MdEdit />,
    title: 'Dissertation',
    description: 'Reaching the final stages of your academic journey is an achievement in itself, but writing a Ph.D. dissertation can be incredibly challenging. Don’t risk stumbling at the finish line. Our Ph.D. dissertation writing service provides expert assistance to ensure your work impresses even the most meticulous professors. Trust us to help you succeed with confidence.',
    delay: '0.5s',
  },
  {
    icon: <MdFileCopy />,
    title: 'Research Paper',
    description: "Writing research papers can be overwhelming, especially with a busy schedule. If you're dreading the task, let us help. Our custom research paper writing service connects you with professional writers who can deliver top-quality work quickly and efficiently. Take the stress off your plate and focus on what matters most.",
    delay: '0.6s',
  },
  {
    icon: <MdAssignment />,
    title: 'Essay',
    description: "School life is full of unforgettable moments, but essays can be a burden. Imagine getting through college without having to write another essay, especially in subjects you're not passionate about. With our service, you can submit a well-crafted, expertly-written paper without the hassle of research. Make your academic journey smoother and more enjoyable with our help!",
    delay: '0.7s',
  },
  {
    icon: <MdFileCopy />,
    title: 'Coursework',
    description: "Coursework can be one of the toughest and most time-consuming college tasks, demanding deep knowledge and significant effort. It often plays a crucial role in determining your overall grade. If you're struggling to achieve the results you want despite your hard work, we’re here to help. Our expert service ensures your coursework is flawless and helps you avoid unnecessary stress.",
    delay: '0.9s',
  },
  {
    icon: <MdBarChart />,
    title: 'Data Analysis & Statistics',
    description: "Our data and statistics teams are deeply integrated within local contexts, ensuring that the insights we provide are both relevant and actionable. From comprehensive statistical analysis to data visualization, we help you make informed decisions with confidence. Utilizing advanced tools like Tableau and Power BI, we transform complex data into clear, impactful visuals, making it easier for you to understand and leverage your data effectively.",
    delay: '1.1s',
  },
];

const ServiceSection = () => {
  return (
    <>
    <PageHeader title="SERVICES" backgroundImage={HeaderImage} height="400px" />
    <Section>
      
      <Container>
        <SectionHeader>
          <h2>Our Services</h2>
          <p>
            Explore our wide range of academic writing services. From assignments to Ph.D. dissertations, our expert team is here to help you succeed.
          </p>
        </SectionHeader>
        <ServicesGrid>
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              style={{ animationDelay: service.delay }}
              className="wow fadeInUp"
            >
              <IconWrapper>
                {service.icon}
              </IconWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
              <OrderButton 
                to="/order" 
                aria-label={`Order ${service.title}`}
              >
                Order Now
              </OrderButton>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </Container>
    </Section>
    </>
  );
};

export default ServiceSection;
