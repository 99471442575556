// frontend/src/App.js
import React from 'react';


import Landing from './Components/screens/Landing'
 // Create this component
const App = () => {
  return (
   
      <Landing/>
    
  );
};



export default App;
