import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const OrdersContainer = styled.div`
  background-color: #f4f6f9; 
  padding: 20px;
  min-height: 100vh;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Tab = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  color: ${({ active }) => (active ? '#025B3A' : '#333')};
  border-bottom: ${({ active }) => (active ? '3px solid #025B3A' : 'none')};

  &:hover {
    color: #025B3A;
  }
`;

const OrderList = styled.div`
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const OrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
`;

const OrderDetails = styled.div`
  flex: 1;
  padding-left: 20px;
`;

const OrderID = styled.h3`
  margin: 0;
  font-size: 1.2em;
  color: #025B3A;
`;

const OrderDescription = styled.p`
  color: #555;
`;

const Button = styled.button`
  background-color: #025B3A;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #02245B;
  }
`;

const MyOrders = () => {
  const [activeTab, setActiveTab] = useState('recent'); // recent, finished, canceled
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    // Fetch orders based on active tab
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/orders?status=${activeTab}`);
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <OrdersContainer>
      <Tabs>
        <Tab active={activeTab === 'recent'} onClick={() => handleTabChange('recent')}>
          Recent
        </Tab>
        <Tab active={activeTab === 'finished'} onClick={() => handleTabChange('finished')}>
          Finished
        </Tab>
        <Tab active={activeTab === 'canceled'} onClick={() => handleTabChange('canceled')}>
          Canceled
        </Tab>
      </Tabs>

      <OrderList>
        {orders.length > 0 ? (
          orders.map((order) => (
            <OrderItem key={order.id}>
              <OrderDetails>
                <OrderID>Order #{order.id}</OrderID>
                <OrderDescription>{order.description}</OrderDescription>
              </OrderDetails>
              <Button>View Details</Button>
            </OrderItem>
          ))
        ) : (
          <div style={{ textAlign: 'center', padding: '50px 0' }}>
            <img src="/empty-box.png" alt="No Orders" />
            <p>There are no orders in this section.</p>
            <Button>New Order</Button>
          </div>
        )}
      </OrderList>
    </OrdersContainer>
  );
};

export default MyOrders;
