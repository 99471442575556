// frontend/src/Api/Api.js
import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: 'http://localhost:5000', // Ensure this matches your backend URL and port
});

// Login Function
export const login = async (credentials) => {
  const response = await api.post('/login', credentials);
  return response.data;
};

// Signup Function
export const signup = async (data) => {
  const response = await api.post('/signup', data);
  return response.data;
};

// Get User Info
export const getUser = async (token) => {
  const response = await api.get('/user', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Export other API functions as needed
