import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaGraduationCap, FaFileAlt, FaUserAlt } from "react-icons/fa"; // Import icons
import AcademicLevelSelector from "../Elements/Academic";
import PaperDetailsForm from "../Elements/PaperDetail";
import UserInfoForm from "../Elements/UserInformation";
import PriceCalculatorCard from "../Elements/PricingCalculator";

const SimpleOrderForm = () => {
  const [formData, setFormData] = useState({
    academicLevel: "Undergrad. (yrs 3-4)",
    typeOfPaper: "",
    discipline: "",
    topic: "Writer's choice",
    instructions: "",
    additionalMaterials: null,
    paperFormat: "APA",
    deadline: "3 Days",
    writerCategory: "Best available",
    getWriterSamples: false,
    expertProofreading: false,
    vipSupport: false,
    getDraftOutline: false,
    totalPrice: 12.0,
    couponCode: "",
    email: "",
    password: "",
    name: "",
    phone: "",
  });

  const calculateTotalPrice = () => {
    let basePrice = 12.0;
    let levelMultiplier = 1;

    switch (formData.academicLevel) {
      case "High school":
        levelMultiplier = 1;
        break;
      case "Undergrad. (yrs 1-2)":
        levelMultiplier = 1.2;
        break;
      case "Undergrad. (yrs 3-4)":
        levelMultiplier = 1.4;
        break;
      case "Master's":
        levelMultiplier = 1.6;
        break;
      case "PhD":
        levelMultiplier = 1.8;
        break;
      default:
        levelMultiplier = 1;
    }

    let writerMultiplier = 1;
    switch (formData.writerCategory) {
      case "Advanced":
        writerMultiplier = 1.25;
        break;
      case "ENL":
        writerMultiplier = 1.3;
        break;
      default:
        writerMultiplier = 1;
    }

    const writerSamplesPrice = formData.getWriterSamples ? 5 : 0;
    const proofreadingPrice = formData.expertProofreading ? 6 : 0;
    const vipSupportPrice = formData.vipSupport ? 9.99 : 0;
    const draftOutlinePrice = formData.getDraftOutline ? 6 : 0;

    return (
      basePrice * levelMultiplier * writerMultiplier +
      writerSamplesPrice +
      proofreadingPrice +
      vipSupportPrice +
      draftOutlinePrice
    ).toFixed(2);
  };

  useEffect(() => {
    const newPrice = calculateTotalPrice();
    setFormData((prevData) => ({ ...prevData, totalPrice: newPrice }));
  }, [
    formData.academicLevel,
    formData.writerCategory,
    formData.getWriterSamples,
    formData.expertProofreading,
    formData.vipSupport,
    formData.getDraftOutline,
  ]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
  };

  return (
    <Container>
      <OrderRow>
        <LeftColumn>
          <SectionBanner>
            <FaGraduationCap size={24} /> {/* Graduation Cap Icon */}
            <BannerText>1. Academic Level</BannerText>
          </SectionBanner>
          <ContentCard>
            <form onSubmit={handleSubmit}>
              <AcademicLevelSelector formData={formData} setFormData={setFormData} />
            </form>
          </ContentCard>

          <SectionBanner>
            <FaFileAlt size={24} /> {/* File Document Icon */}
            <BannerText>2. Paper Details</BannerText>
          </SectionBanner>
          <ContentCard>
            <h5>Paper Details</h5>
            <form onSubmit={handleSubmit}>
              <PaperDetailsForm formData={formData} handleChange={handleChange} />
              <WriterCategoryCard>
                <h6>Writer Category</h6>
                <ButtonGroup>
                  <WriterButton
                    active={formData.writerCategory === "Best available"}
                    onClick={() =>
                      setFormData({ ...formData, writerCategory: "Best available" })
                    }
                  >
                    Best available <br />
                    <small>Standard price</small>
                  </WriterButton>
                  <WriterButton
                    active={formData.writerCategory === "Advanced"}
                    onClick={() =>
                      setFormData({ ...formData, writerCategory: "Advanced" })
                    }
                  >
                    Advanced <br />
                    <small>+25%</small>
                  </WriterButton>
                  <WriterButton
                    active={formData.writerCategory === "ENL"}
                    onClick={() => setFormData({ ...formData, writerCategory: "ENL" })}
                  >
                    ENL <br />
                    <small>+30%</small>
                  </WriterButton>
                </ButtonGroup>
              </WriterCategoryCard>

              <AdditionalServicesCard>
                <h6>Additional Services</h6>
                <CheckboxContainer>
                  <label>
                    <input
                      type="checkbox"
                      name="getWriterSamples"
                      checked={formData.getWriterSamples}
                      onChange={handleChange}
                    />
                    Get Writer Samples ($5.00)
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="expertProofreading"
                      checked={formData.expertProofreading}
                      onChange={handleChange}
                    />
                    Expert Proofreading ($6.00)
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="vipSupport"
                      checked={formData.vipSupport}
                      onChange={handleChange}
                    />
                    VIP Support ($9.99)
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="getDraftOutline"
                      checked={formData.getDraftOutline}
                      onChange={handleChange}
                    />
                    Get Draft/Outline ($6.00)
                  </label>
                </CheckboxContainer>
              </AdditionalServicesCard>

              <SubmitButton type="submit">
                Safe checkout - ${formData.totalPrice}
              </SubmitButton>
            </form>
          </ContentCard>

          <SectionBanner>
            <FaUserAlt size={24} /> {/* User Icon */}
            <BannerText>3. Account</BannerText>
          </SectionBanner>
          <CenteredUserInfoForm>
            <UserInfoForm formData={formData} handleChange={handleChange} />
          </CenteredUserInfoForm>
        </LeftColumn>

        <RightColumn>
          <StickyCard>
            <PriceCalculatorCard formData={formData} />
          </StickyCard>
        </RightColumn>
      </OrderRow>
    </Container>
  );
};

export default SimpleOrderForm;

// Styled Components
const Container = styled.div`
  padding: 50px 15px;
  background-color: #f2f8f8;
  max-width: 1200px;
  margin: 0 auto;
`;

const OrderRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1 1 65%;
  padding-right: 20px;
  min-width: 300px;

  @media (max-width: 768px) {
    padding-right: 0;
    width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1 1 30%;
  min-width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ContentCard = styled.div`
  background: white;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const WriterCategoryCard = styled(ContentCard)``;

const ButtonGroup = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const WriterButton = styled.button`
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  background-color: ${({ active }) => (active ? "#007bff" : "#f8f9fa")};
  color: ${({ active }) => (active ? "#fff" : "#000")};
  border: 1px solid ${({ active }) => (active ? "#007bff" : "#ccc")};
  cursor: pointer;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const AdditionalServicesCard = styled(ContentCard)``;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled.button`
  width: 100%;
  background-color: #007bff;
  color: white;
  padding: 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
`;

const CenteredUserInfoForm = styled.div`
  margin-left: 80px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const SectionBanner = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 15px;
`;

const BannerText = styled.h1`
  font-size: 20px;
  color: #333;
  margin-left: 10px;
`;

const StickyCard = styled(ContentCard)`
  position: sticky;
  top: 100px;

  @media (max-width: 768px) {
    position: static;
  }
`;
