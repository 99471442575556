import React from 'react';
// import Sidebar from './Sidebar';
import MyOrders from './Oders';
import DashboardHeader from './DashboardHeader'; // Include the header with the user dropdown
import styled from 'styled-components';

const DashboardContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  flex: 1;
  background-color: #f4f6f9; 
  padding: 20px;
  min-height: 100vh;
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      {/* <Sidebar /> Sidebar for navigation */}
      
      <MainContent>
        <DashboardHeader /> {/* Header with user dropdown */}
        
        <MyOrders /> {/* The orders section */}
        
        {/* Additional content like statistics, widgets, etc. */}
      </MainContent>
    </DashboardContainer>
  );
};

export default Dashboard;
