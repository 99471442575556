
// src/Components/Sections/Contact.jsx
import React, { useState } from "react";
import styled from "styled-components";
import XIcon from "../Elements/x";  // Placeholder for the "X" icon if used
import emailjs from "emailjs-com";
import { 
  FaWhatsapp, 
  FaFacebookF, 
  FaInstagram, 
  FaLinkedinIn, 
  FaTwitter, 
  FaYoutube 
} from "react-icons/fa";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons'; // Correctly import Twitter/X icon

// Styled Components
const Section = styled.section`
  padding: 60px 20px;
  background-color: #f3f4f6;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  color: #2C3E50;
  margin-bottom: 50px;
  font-size: 2.5rem;
  font-weight: 700;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start; /* Prevent stretching */
`;

const Card = styled.div`
  flex: 1 1 280px; /* Allows the card to grow and shrink with a base width */
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 768px) {
    flex: 1 1 100%; /* Full width on small screens */
    padding: 20px;
  }
`;

const FormContainer = styled(Card)`
  flex: 2 1 580px; /* Takes up twice the space of a regular card */

  @media (max-width: 768px) {
    flex: 1 1 100%; /* Full width on small screens */
  }
`;

const IconWrapper = styled.div`
  font-size: 24px; /* Adjusted font size for better visibility */
  color: #2C3E50;
  margin-bottom: 10px;

  /* Optional: Adjust icon size responsively */
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s;

  &:focus {
    border-color: #2C3E50;
    outline: none;
    box-shadow: 0 0 5px rgba(44, 62, 80, 0.2);
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  resize: vertical;
  transition: border-color 0.3s;

  &:focus {
    border-color: #2C3E50;
    outline: none;
    box-shadow: 0 0 5px rgba(44, 62, 80, 0.2);
  }
`;

const Button = styled.button`
  background-color: #2C3E50;
  color: white;
  padding: 14px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #34495e;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(44, 62, 80, 0.3);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 20px;

  a {
    color: #2C3E50;
    font-size: 1.5rem;
    transition: color 0.3s;

    &:hover {
      color: #1ABC9C;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SuccessMessage = styled.p`
  color: #27ae60;
  margin-top: 15px;
  text-align: center;
  font-weight: 500;
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  margin-top: 15px;
  text-align: center;
  font-weight: 500;
`;
const ContactInfoRow = styled.div`
  display: flex;
  align-items: center; /* Aligns icon and text vertically */
  gap: 10px; /* Adds space between the icon and the phone number */
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_fecn2o6", // Replace with your EmailJS service ID
        "template_gzcqjyy", // Replace with your EmailJS template ID
        event.target,
        "RFG8hpt-rjgQw3EXR" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("Email sent successfully!", response.status, response.text);
          setIsSent(true);
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
          setIsSubmitting(false);
          setError(null);
        },
        (error) => {
          console.error("Error sending email:", error);
          setIsSent(false);
          setIsSubmitting(false);
          setError("Failed to send message. Please try again later.");
        }
      );
  };

  return (
    <Section id="contact">
      <Container>
        <Title>Contact Our Academic Writing Team</Title>
        <Row>
          {/* About Elite Essay Aid Card */}
          <Card>
            <h3>Elite Essay Aid</h3>
            <p>Professional academic writing assistance for your success. Our expert writers can assist with essays, research papers, and more.</p>
          </Card>

          {/* Contact Info Card */}
          <Card>
          <h4>Contact Info</h4>
            <p>
              <ContactInfoRow>
                📧
                <a href="mailto:info@eliteessayaid.com" style={{ color: "#2C3E50", textDecoration: "none" }}>
                  info@eliteessayaid.com
                </a>
              </ContactInfoRow>
            </p>
            <p>
              <ContactInfoRow>
                📞
                <a href="tel:+17722899299" style={{ color: "#2C3E50", textDecoration: "none" }}>
                  +1(17722899299)
                </a>
              </ContactInfoRow>
            </p>

<SocialLinks>
              {/* WhatsApp */}
              <a
                href="https://wa.me/17722899299?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="WhatsApp"
              >
                <IconWrapper>
                  <FaWhatsapp />
                </IconWrapper>
              </a>
              {/* Facebook */}
              <a
                href="https://www.facebook.com/profile.php?id=61566787580146"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <IconWrapper>
                  <FaFacebookF />
                </IconWrapper>
              </a>
              {/* Instagram */}
              <a href="https://www.instagram.com/eliteessayaid/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <IconWrapper>
                  <FaInstagram />
                </IconWrapper>
              </a>
              {/* LinkedIn */}
              <a href="https://www.linkedin.com/company/elite-essay-aid" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <IconWrapper>
                  <FaLinkedinIn />
                </IconWrapper>
              </a>
              {/* X (formerly Twitter) */}
              <a href="https://x.com/Elite_Essay_Aid?t=DBRSle0-n7RNXaBWxwMAUQ&s=09" target="_blank" rel="noopener noreferrer" aria-label="X (formerly Twitter)">
                <IconWrapper>
                  <FontAwesomeIcon icon={faXTwitter} />
                </IconWrapper>
              </a>
              {/* YouTube */}
              <a href="https://www.youtube.com/channel/yourchannel" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                <IconWrapper>
                  <FaYoutube />
                </IconWrapper>
              </a>
            </SocialLinks>
          </Card>

          {/* Contact Form Card */}
          <FormContainer>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  aria-label="Your Name"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  aria-label="Your Email"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="subject"
                  placeholder="Subject (e.g., Essay Inquiry)"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  aria-label="Subject"
                />
              </FormGroup>
              <FormGroup>
                <Textarea
                  name="message"
                  rows="5"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  aria-label="Your Message"
                />
              </FormGroup>
              <Button type="submit" disabled={isSubmitting} aria-disabled={isSubmitting}>
                {isSubmitting ? "Sending..." : "Send Message"}
              </Button>
              {isSent && <SuccessMessage>Your message has been sent. Thank you!</SuccessMessage>}
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </form>
          </FormContainer>
        </Row>
      </Container>
    </Section>
  );
};

export default Contact;
