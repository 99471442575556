// src/components/Landing.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from "styled-components";
import { AuthProvider } from "../context/AutoContex"; // Ensure correct path
import PrivateRoute from "../PrivateRoutes"; // Ensure correct path

// Sections
import TopNavbar from "../Nav/TopNavbar";
import Sidebar from "../Nav/Sidebar";
import Home from "../Sections/Home";
import About from "../Sections/about";
import Contact from "../Sections/Contact";
import Service from "../Sections/Service";
import Footer from "../Sections/Footer";
import Order from "../Sections/order";
import Login from "../Login/login"; // Create this component
import Signup from "../Login/Signup";
import Dashboard from "../Sections/Dashboard/Dashboard"; // Create this component
import ScrollToTop from "./ScrolTop";

const MainContent = styled.div`
  margin-top: 80px; // Adjust this based on your navbar height
  padding: 0 5px; // Optional padding for horizontal spacing
`;
export default function Landing() {
  return (
    <AuthProvider>
      <Router>
        <TopNavbar />
        <Sidebar />
        <ScrollToTop />
        <MainContent>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/order" element={<Order />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup/>} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            {/* Add more routes as needed */}
          </Routes>
        </MainContent>
        <Footer />
      </Router>
    </AuthProvider>
  );
}