// frontend/src/components/LoginForm.js

import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import '@fontsource/poppins'; // Regular
import '@fontsource/montserrat'; // Bold
import { AuthContext } from '../context/AutoContex'; // Ensure the path is correct
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const LoginForm = () => {
  const [credentials, setCredentials] = useState({ usernameOrEmail: '', password: '' });
  const { loginUser } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Client-side Validation
  const validate = () => {
    const errors = {};
    if (!credentials.usernameOrEmail.trim()) {
      errors.usernameOrEmail = 'Username or Email is required.';
    }
    if (!credentials.password) {
      errors.password = 'Password is required.';
    } else if (credentials.password.length < 4) {
      errors.password = 'Too Short';
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    setFieldErrors({});
    setLoading(true);

    try {
      const result = await loginUser(credentials);
      if (result.success) {
        navigate('/dashboard');
      } else {
        setError(result.message);
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header> 
        <IconWrapper>
          <FontAwesomeIcon icon={faUser} />
        </IconWrapper>
        
        <TitleIconContainer>
        
          <Title>Login</Title>
         
        </TitleIconContainer>
      </Header>
      <Card>
        <StyledForm onSubmit={handleSubmit} noValidate>
          {error && <ErrorMessage role="alert">{error}</ErrorMessage>}

          {/* Username or Email Input */}
          <FormGroup>
            <StyledInput
              type="text"
              name="usernameOrEmail"
              value={credentials.usernameOrEmail}
              onChange={handleChange}
              placeholder="username"
              required
              hasError={!!fieldErrors.usernameOrEmail}
              aria-label="Username or Email"
              aria-invalid={!!fieldErrors.usernameOrEmail}
              aria-describedby="usernameOrEmail-error"
            />
            {fieldErrors.usernameOrEmail && (
              <FieldError id="usernameOrEmail-error">{fieldErrors.usernameOrEmail}</FieldError>
            )}
          </FormGroup>

          {/* Password Input */}
          <FormGroup>
            <StyledInput
              type="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Enter your password"
              required
              hasError={!!fieldErrors.password}
              aria-label="Password"
              aria-invalid={!!fieldErrors.password}
              aria-describedby="password-error"
            />
            {fieldErrors.password && (
              <FieldError id="password-error">{fieldErrors.password}</FieldError>
            )}
          </FormGroup>

          {/* Submit Button */}
          <FormGroup>
            <StyledButton type="submit" disabled={loading} aria-busy={loading}>
              {loading ? 'Logging In...' : 'Login'}
            </StyledButton>
          </FormGroup>

          {/* Sign Up Link */}
          <FormGroup>
            <SignUpLink>
              Don't have an account? <StyledLink to="/signup">Sign Up</StyledLink>
            </SignUpLink>
          </FormGroup>
        </StyledForm>
      </Card>
    </Container>
  );
};

export default LoginForm;

// Styled Components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #ffff;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
`;
const Header = styled.div`
  width: 40%;
  height: 10vh;
  display: flex;
  justify-content: center; /* Move all content to the right */
  align-items: center;
  background: linear-gradient(135deg, #025B50, #023A5B);
  color: white;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 20px; /* Add horizontal padding */
  border-radius: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight:700px;

  @media (max-width: 600px) {
    height: 15vh;
    flex-direction: column; /* Stack vertically on small screens */
    justify-content: center;
  }
`;

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px; /* Pushes the container to the right */
`;
const Title = styled.h1`
  font-size: 2.0rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-right: 20px;
  font-weight: 700; /* Increase font weight */

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 10px; /* Add spacing when stacked */
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  color: #ffffff;

  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const Card = styled.div`
  padding: 30px;
  width: 70%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  @media (max-width: 600px) {
    padding: 20px;
    width: 90%;
  }
`;

const StyledForm = styled.form`
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 14px;
  border: 1px solid ${(props) => (props.hasError ? '#FF6B6B' : '#d3d3d3')};
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Poppins', sans-serif;

  &:focus {
    border-color: #02505B;
    box-shadow: 0 0 5px rgba(2, 91, 59, 0.5);
    outline: none;
  }

  &::placeholder {
    color: #b0b0b0;
    opacity: 1;
    font-family: 'Poppins', sans-serif;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 14px;
  background-color: #02505B;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #02245B;
    transform: scale(1.02);
  }

  &:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
    transform: none;
  }

  &:focus {
    outline: 2px solid #025B50;
    outline-offset: 2px;
  }
`;

const ErrorMessage = styled.p`
  color: #FF6B6B;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
`;

const FieldError = styled.span`
  color: #FF6B6B;
  font-size: 0.85rem;
  position: absolute;
  bottom: -18px;
  left: 0;
  font-family: 'Poppins', sans-serif;
`;

const SignUpLink = styled.p`
  text-align: center;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
`;

const StyledLink = styled(Link)`
  color: #025B50;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: #023A5B;
  }

  &:focus {
    outline: 2px solid #025B50;
    outline-offset: 2px;
  }
`;
