// frontend/src/context/AuthContext.js
import React, { createContext, useState } from 'react';
import { login } from '../API/Api'; // Ensure this path is correct

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const existingTokens = localStorage.getItem('token');
  const [authTokens, setAuthTokens] = useState(existingTokens || null);

  const setTokens = (data) => {
    localStorage.setItem('token', data);
    setAuthTokens(data);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    setAuthTokens(null);
  };

  const loginUser = async (credentials) => {
    try {
      const response = await login({
        username: credentials.usernameOrEmail,
        password: credentials.password,
      });
      setTokens(response.token); // Ensure the backend sends 'token'
      return { success: true };
    } catch (err) {
      if (err.response && err.response.data) {
        return { success: false, message: err.response.data.message };
      } else {
        return { success: false, message: 'An error occurred. Please try again.' };
      }
    }
  };

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, loginUser, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};
