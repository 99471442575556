import React, { useState } from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #025B3A;
  color: white;
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Username = styled.span`
  margin-right: 10px;
  font-weight: bold;
`;

const DropdownMenu = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 50px;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const DropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f4f4f4;
  }
`;

const DashboardHeader = ({ username }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <HeaderContainer>
      <h2>My Dashboard</h2>
      <UserSection onClick={handleDropdownToggle}>
        <Avatar src="/user-icon.png" alt="User Avatar" />
        <Username>{username}</Username>
        <DropdownMenu show={showDropdown}>
          <DropdownItem>Profile</DropdownItem>
          <DropdownItem>Settings</DropdownItem>
          <DropdownItem>Log Out</DropdownItem>
        </DropdownMenu>
      </UserSection>
    </HeaderContainer>
  );
};

export default DashboardHeader;
