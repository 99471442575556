import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
// AOS
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use a custom AOS stylesheet

export default function Header() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1000ms
  }, []);

  return (
    <HeroWrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter" data-aos="fade-right">
        <div>
          <MainHeading
            className="extraBold font60"
            data-aos="fade-down"
            data-aos-delay="1000"
          >
            Achieve Academic Success with Expert Assistance
          </MainHeading>
          <SubText
            className="font13 semiBold"
            data-aos="fade-right"
            data-aos-delay="700"
          >
            Professional, reliable, and affordable essay writing services
            tailored to your academic needs. Trust us to help you excel in your
            studies.
          </SubText>
          <BtnWrapper data-aos="fade-up" data-aos-delay="400">
            <FullButton title="Get Started Now" />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide data-aos="fade-left">
        <ImageWrapper>
          <iframe
            className="radius8"
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/Cq4J8bPBcck?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <QuoteWrapper
            className="flexCenter darkBg radius8"
            data-aos="zoom-in"
            data-aos-delay="600"
          >
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <QuoteText className="font15 whiteColor">
                <em>
                  "Their attention to detail and professionalism helped me
                  submit my best work. Highly recommended!"
                </em>
              </QuoteText>
              <StudentName className="font13 orangeColor textRight">
                – Satisfied Student
              </StudentName>
            </div>
          </QuoteWrapper>
        </ImageWrapper>
      </RightSide>
    </HeroWrapper>
  );
}

// Styled Components
const HeroWrapper = styled.section`
  width: 100vw; // Full width
  height: 100vh; // Full height of the screen

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px; // Keep minimal padding for a full-screen look
  @media (max-width: 960px) {
    flex-direction: column;
    padding: 30px 10px;
    height: auto; // Allow auto height for mobile
  }
`;

const LeftSide = styled.div`
  width: 50%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    text-align: center;
    margin-top: 20px;
  }
`;

const RightSide = styled.div`
  width: 50%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: center;
  }
`;

const MainHeading = styled.h1`
  font-size: 3rem; // Slightly larger for emphasis
  color: #333;
  margin-top: -10px;
  margin-bottom: 20px;
  line-height: 1.2;
  @media (max-width: 960px) {
    font-size: 2.5rem;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 2rem; // Smaller for smaller screens
  }
  @media (max-width: 480px) {
    font-size: 1.8rem; // For very small screens
  }
`;

const SubText = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  margin-top: -10px; /* Adjusted to move it up */
  max-width: 500px;
  line-height: 1.6rem;
  @media (max-width: 960px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 0.9rem; // Slightly smaller on tablets
  }
  @media (max-width: 480px) {
    font-size: 0.8rem; // Even smaller on very small screens
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 40%; // Full width for the button

  @media (max-width: 960px) {
    width: 100%; // Adjust button width for mobile
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 500px;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: -10px;
  max-width: 330px;
  padding: 20px;
  background-color: #222;
  color: #fff;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
 @media (max-width: 560px) {
  bottom: -40px;
  left: 50%; 
  max-width: 50%;
  padding: 10px;
  display: none; /* This hides the element */
}
`;

const QuotesWrapper = styled.div`
  position: absolute;
  left: 50px;
  top: -20px;
`;

const QuoteText = styled.p`
  font-size: 1rem;
`;

const StudentName = styled.p`
  font-size: 1rem;
  text-align: right;
  color: #ff6347;
`;
